
// To override this file create a plugins-dev.js one and copy the content of the plugin.js one.
// When starting the app the script will copy the plugins-dev.js into this one instead.
import contentTypeBuilder from '../../../content-type-builder/admin/src';
import email from '../../../email/admin/src';
import upload from '../../../upload/admin/src';
import colorPicker from '../../../../plugins/color-picker/admin/src';
import documentation from '../../../../plugins/documentation/admin/src';
import graphql from '../../../../plugins/graphql/admin/src';
import i18N from '../../../../plugins/i18n/admin/src';
import sentry from '../../../../plugins/sentry/admin/src';
import strapiPluginCkeditor from '../../../../plugins/strapi-plugin-ckeditor/admin/src';
import strapiPluginMultiSelect from '../../../../plugins/strapi-plugin-multi-select/admin/src';
import usersPermissions from '../../../../plugins/users-permissions/admin/src';

const plugins = {
  'content-type-builder': contentTypeBuilder,
  'email': email,
  'upload': upload,
  'color-picker': colorPicker,
  'documentation': documentation,
  'graphql': graphql,
  'i18n': i18N,
  'sentry': sentry,
  'strapi-plugin-ckeditor': strapiPluginCkeditor,
  'strapi-plugin-multi-select': strapiPluginMultiSelect,
  'users-permissions': usersPermissions,
};
  
export default plugins;
